import React from 'react';
import {ImportButton} from "react-admin-import-csv";
import {CreateButton, ExportButton} from "ra-ui-materialui";
import {
    List,
    Show,
    Create,
    Edit,
    Datagrid,
    SimpleShowLayout,
    SimpleForm,
    TextField,
    UrlField,
    BooleanField,
    TextInput,
    BooleanInput,
    required, TopToolbar
} from 'react-admin';

const config = {
    parseConfig: {
        header: true,
        dynamicTyping: true
    }
}

const BrandActions = props => {
    const {
        className,
        basePath,
        total,
        resource,
        currentSort,
        filterValues,
        exporter
    } = props;
    return (
        <TopToolbar className={className}>
            <CreateButton basePath={basePath}/>
            <ImportButton {...props} {...config}/>
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </TopToolbar>
    );
};

export const BrandList = (props) => (
    <List {...props} actions={<BrandActions/>}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="country" />
            <UrlField source="url" />
            <BooleanField source="isBikeMaker" />
        </Datagrid>
    </List>
);

export const BrandShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="country" />
            <UrlField source="url" />
            <BooleanField source="isBikeMaker" />
        </SimpleShowLayout>
    </Show>
);

export const BrandCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput disabled source="id" label="Autogenerated id"/>
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="country" validate={[required()]}/>
            <TextInput source="url" validate={[required()]}/>
            <BooleanInput source="isBikeMaker" defaultValue={false} validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export const BrandEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" validate={[required()]}/>
            <TextInput source="country" validate={[required()]}/>
            <TextInput source="url" validate={[required()]}/>
            <BooleanInput source="isBikeMaker" defaultValue={false} validate={[required()]}/>
        </SimpleForm>
    </Edit>
);