import {
    FirebaseDataProvider,
    FirebaseAuthProvider,
} from "react-admin-firebase";

const config = {
    apiKey: "AIzaSyAALPAg00EG-WhznEOqsu6k34K_75xpY9w",
    authDomain: "cycle-api.firebaseapp.com",
    databaseURL: "https://cycle-api.firebaseio.com",
    projectId: "cycle-api",
    storageBucket: "cycle-api.appspot.com",
    messagingSenderId: "10555347877",
    appId: "1:10555347877:web:2631e421b04911c8fd4e4c"
};

const options = {};

export const dataProvider = FirebaseDataProvider(config, options);
export const authProvider = FirebaseAuthProvider(config, options);