import React from 'react';
import './App.css';
import {authProvider, dataProvider} from './firestore'

import ClassIcon from '@material-ui/icons/Book';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import StorefrontIcon from '@material-ui/icons/Storefront';

import {CustomLoginPage} from './customLoginPage'
import {BrandCreate, BrandEdit, BrandList, BrandShow} from './brands'
import {BikeCreate, BikeEdit, BikeList, BikeShow} from './bikes'
import {CategoryCreate, CategoryEdit, CategoryList} from './categories'
import {Admin, Resource} from "react-admin";

const App = () => (
    <Admin
        loginPage={CustomLoginPage}
        dataProvider={dataProvider}
        authProvider={authProvider}>
        <Resource name="brands" icon={StorefrontIcon} list={BrandList} show={BrandShow} create={BrandCreate}
                  edit={BrandEdit}/>
        <Resource name="bikes" icon={DirectionsBikeIcon} list={BikeList} show={BikeShow} create={BikeCreate}
                  edit={BikeEdit}/>
        <Resource name="categories" icon={ClassIcon} list={CategoryList} create={CategoryCreate} edit={CategoryEdit}/>
    </Admin>
);

export default App;
