import React from 'react';
import {ImportButton} from "react-admin-import-csv";
import {CreateButton, ExportButton} from "ra-ui-materialui";
import {
    ArrayField,
    ArrayInput,
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    DateField,
    DateInput,
    Edit,
    List,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectArrayInput,
    SelectInput,
    Show,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TextInput, TopToolbar
} from 'react-admin';

const colorChoices = [
    {color: "silver"},
    {color: "beige"},
    {color: "brown"},
    {color: "white"},
    {color: "blue"},
    {color: "grey"},
    {color: "yellow"},
    {color: "kaki"},
    {color: "black"},
    {color: "gold"},
    {color: "orange"},
    {color: "pink"},
    {color: "red"},
    {color: "green"},
    {color: "violet"},
    {color: "unknown"}
]

const BikeActions = props => {
    const {
        className,
        basePath,
        total,
        resource,
        currentSort,
        filterValues,
        exporter
    } = props;
    return (
        <TopToolbar className={className}>
            <CreateButton basePath={basePath}/>
            <ImportButton {...props} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </TopToolbar>
    );
};

export const BikeList = (props) => (
    <List {...props} actions={<BikeActions/>}>
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <ReferenceField source="brandId" reference="brands">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="model"/>
            <TextField source="version"/>
            <DateField source="releaseDate"/>
            <BooleanField source="isElectric"/>
        </Datagrid>
    </List>
)

export const BikeShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id"/>
            <ReferenceField source="brandId" reference="brands">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="model"/>
            <TextField source="version"/>
            <DateField source="releaseDate"/>
            <ArrayField source="colors">
                <SingleFieldList>
                    <ChipField source="color"/>
                </SingleFieldList>
            </ArrayField>
            <BooleanField source="isElectric"/>
            <BooleanField source="isFolding"/>
            <BooleanField source="hasSuspensionFront"/>
            <BooleanField source="hasSuspensionRear"/>
            <ReferenceArrayField label="Categories" source="categoryId" reference="categories">
                <SingleFieldList>
                    <ChipField source="name"/>
                </SingleFieldList>
            </ReferenceArrayField>
        </SimpleShowLayout>
    </Show>
)

export const BikeCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput disabled source="id" label="Autogenerated id"/>
            <ReferenceInput source="brandId" reference="brands" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="model" validate={[required()]}/>
            <TextInput source="version"/>
            <DateInput source="releaseDate" allowEmpty/>
            <ArrayInput source="colors">
                <SimpleFormIterator>
                    <AutocompleteInput source="color" choices={colorChoices} label="Color" optionText="color"
                                       optionValue="color"/>
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput source="isElectric" defaultValue={false} validate={[required()]}/>
            <BooleanInput source="isFolding" defaultValue={false} validate={[required()]}/>
            <BooleanInput source="hasSuspensionFront" defaultValue={false} validate={[required()]}/>
            <BooleanInput source="hasSuspensionRear" defaultValue={false} validate={[required()]}/>
            <ReferenceArrayInput label="Categories" source="categoryId" reference="categories" validate={[required()]}>
                <SelectArrayInput optionText="name" optionValue="id"/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
)

export const BikeEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" label="Autogenerated id" validate={[required()]}/>
            <ReferenceInput source="brandId" reference="brands" validate={[required()]}>
                <SelectInput optionText="name"/>
            </ReferenceInput>
            <TextInput source="model" validate={[required()]}/>
            <TextInput source="version"/>
            <DateInput source="releaseDate" allowEmpty/>
            <ArrayInput source="colors">
                <SimpleFormIterator>
                    <AutocompleteInput source="color" choices={colorChoices} label="Color" optionText="color"
                                       optionValue="color"/>
                </SimpleFormIterator>
            </ArrayInput>
            <BooleanInput source="isElectric" defaultValue={false} validate={[required()]}/>
            <BooleanInput source="isFolding" defaultValue={false} validate={[required()]}/>
            <BooleanInput source="hasSuspensionFront" defaultValue={false} validate={[required()]}/>
            <BooleanInput source="hasSuspensionRear" defaultValue={false} validate={[required()]}/>
            <ReferenceArrayInput label="Categories" source="categoryId" reference="categories" validate={[required()]}>
                <SelectArrayInput optionText="name" optionValue="id"/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
)
/**
 private List<String> sizes = new ArrayList<>();
 private boolean pedals;
 private ReleaseStatus releaseStatus = ReleaseStatus.RELEASED;

 private List<Part> parts;

 public enum ReleaseStatus {
    ANNOUNCED, RELEASED, DISCONTINUED
}
 **/