import React from "react";
import { Login, LoginForm } from "react-admin";

const CustomLoginForm = props => (
    <div>
        <LoginForm {...props} />
    </div>
);

export const CustomLoginPage = props => (
    <Login {...props}>
        <CustomLoginForm {...props}/>
    </Login>
);