import React from 'react';
import {ImportButton} from "react-admin-import-csv";
import {CreateButton, ExportButton} from "ra-ui-materialui";
import {Create, Datagrid, Edit, List, required, SimpleForm, TextField, TextInput, TopToolbar} from 'react-admin';

const CategoryActions = props => {
    const {
        className,
        basePath,
        total,
        resource,
        currentSort,
        filterValues,
        exporter
    } = props;
    return (
        <TopToolbar className={className}>
            <CreateButton basePath={basePath}/>
            <ImportButton {...props} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
        </TopToolbar>
    );
};

export const CategoryList = (props) => (
    <List {...props} actions={<CategoryActions/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="name"/>
        </Datagrid>
    </List>
);

export const CategoryCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput disabled source="id" label="Autogenerated id"/>
            <TextInput source="name" validate={[required()]}/>
        </SimpleForm>
    </Create>
);

export const CategoryEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" label="Autogenerated id"/>
            <TextInput source="name" validate={[required()]}/>
        </SimpleForm>
    </Edit>
);